export default {
  data () {
    return {
      internalModelValue: this.cloneModelValue(this.modelValue),
    };
  },
  watch: {
    modelValue (newValue) {
      if (newValue !== this.internalModelValue) {
        this.internalModelValue = this.cloneModelValue(this.modelValue);
      }
    },
    internalModelValue (newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
  methods: {
    cloneModelValue (modelValue) {
      return JSON.parse(JSON.stringify(modelValue))
    },
  },
}
