<template>
  <div class="orange-circle orderBy" @click="popoverOpen = !popoverOpen; event = $event">
    <uil-filter size="25px" />
  </div>
  <ion-popover :is-open="popoverOpen" :event="event" @didDismiss="popoverOpen = false" class="filter-popover">
    <ion-content class="ion-padding" scroll-y="false">
      <div class="popover-container">
        <div class="popover-title">
          Filter Jobs
        </div>
        <ion-list>
          <ion-radio-group v-model="internalModelValue" allow-empty-selection>
            <ion-item>
              <ion-label>Submitted Jobs</ion-label>
              <ion-radio slot="start" value="submitted" />
            </ion-item>
            <ion-item>
              <ion-label>Unsubmitted Jobs</ion-label>
              <ion-radio slot="start" value="unsubmitted" />
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </ion-content>
  </ion-popover>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRadio,
  IonRadioGroup,
} from '@ionic/vue';
import {
  UilFilter,
} from '@iconscout/vue-unicons';
import vModelMixin from "@/mixins/v-model";

export default {
  mixins: [
    vModelMixin,
  ],
  components: {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
    IonRadio,
    IonRadioGroup,
    UilFilter,
  },
  props: {
    modelValue: String,
  },
  data () {
    return {
      event: null,
      popoverOpen: false,
    };
  },
};
</script>

<style scoped lang="scss">
.orange-circle {
  border-radius: 50%;
  background-color: #db9123;
  padding: 5px;
  height: 35px;
  width: 35px;
  color: #172943;
}

.popover-title {
  color: #373636;
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 18px;
}
</style>
