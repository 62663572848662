<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="ios hydrated container-page-header">
        <div class="page-title">
          <div />
          <h1>Your Jobs</h1>
          <div class="filter-orderBy">
            <order-popover v-model="orderBy" />
            <filter-popover v-model="filter" />
          </div>
        </div>
      </div>

      <template v-if="sortedAndFilteredJobs.length">
        <ion-card v-for="(job) in sortedAndFilteredJobs" :key="job.id" class="job-card">
          <div class="img-container">
            <div class="action-btn directions">
              <a :href="job.asset.google" target="_blank">
                <uil-directions size="25px" />
              </a>
            </div>
            <template v-if="job.submitted">
              <div class="job-submitted">Submitted</div>
            </template>
            <img style="height: 170px; width: 100%; object-fit: cover;" :src="job.asset.google_static">
          </div>
          <ion-progress-bar class="progress" :value="jobsProgress.byJob[job.id].progressPercent / 100" color="secondary" />
          <ion-card-content>
            <div class="card-container">
              <div>
                <ion-card-title>
                  <h2 class="bold">{{ job.asset.title }}</h2>
                </ion-card-title>
                <ion-card-title>
                  <h2>{{ job.asset.address + ", " + job.asset.city + ", " + job.asset.postcode }}</h2>
                </ion-card-title>
                <ion-card-subtitle class="job-code">#{{ job.job_code }}</ion-card-subtitle>
                <ion-card-subtitle class="job-date">Start Date: {{ job.job_date }}</ion-card-subtitle>
              </div>
              <div>
                <div class="action-open">
                  <uil-file-alt size="25px" @click="openNotesModal(job)" />
                </div>
                <ion-button color="secondary" fill="solid" class="job-view" @click="openJob(job)">View <template v-if="job.submitted">Submitted </template> ></ion-button>
              </div>
            </div>
          </ion-card-content>
        </ion-card>
      </template>
      <template v-else>
        <div class="no-jobs">There are no jobs assigned to you or no jobs match that filter. Jobs may be hidden using the "Hide from phone" feature in the main dashboard or they could be in status "Accepted".</div>
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonPage,
  IonProgressBar,
  modalController,
} from '@ionic/vue';
import { UilFileAlt, UilDirections } from '@iconscout/vue-unicons';
import NotesModal from '@/components/modals/NotesModal';
import FilterPopover from '@/components/popover/FilterPopover';
import OrderPopover from "@/components/popover/OrderPopover";
import mutatesJobsMixin from "@/mixins/mutates-jobs";

export default {
  mixins: [
    mutatesJobsMixin,
  ],
  components: {
    FilterPopover,
    OrderPopover,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonPage,
    IonProgressBar,
    UilDirections,
    UilFileAlt
  },
  data () {
    return {
      filter: null,
      orderBy: null,
    }
  },
  computed: {
    sortedAndFilteredJobs () {
      if (this.jobs) {
        return [].concat(Object.values(this.jobs))
          .filter((job) => {
            if (this.filter) {
              if (this.filter === 'submitted' && job.status === 'Submitted') {
                return true;
              }
              return (this.filter === 'unsubmitted' && job.status !== 'Submitted');
            }
            return true;
          })
          .sort((jobA, jobB) => {
            if (this.orderBy === 'start-date-asc') {
              return (jobA.job_date_timestamp - jobB.job_date_timestamp)
            } else if (this.orderBy === 'start-date-desc') {
              return (jobB.job_date_timestamp - jobA.job_date_timestamp)
            } else {
              return 0;
            }
          });
      }
      return [];
    },
  },
  methods: {
    openJob (job) {
      this.$router.push({ path: '/job/' + job.id })
    },
    async openNotesModal (job) {
      var assetNotes = job.asset.notes,
        surveyorNotes = "";

      if (job.surveyor_notes) {
        surveyorNotes = job.surveyor_notes;
      }
      if (assetNotes === "" || assetNotes == null) {
        assetNotes = "There are no asset notes for this job.";
      }

      const modal = await modalController
        .create({
          component: NotesModal,
          componentProps: {
            notes: assetNotes,
            your_notes: surveyorNotes,
            job: job
          },
        })

      modal.onDidDismiss()
        .then((data) => {
          if (data.data !== undefined) {
            job.surveyor_notes = data.data;
          }
        });

      return await modal.present();
    },
  },
}
</script>

<style scoped lang="scss">
h1 {
  text-align: center;
}

.job-card {
  border-radius: 0;
  margin-bottom: 40px;
  .card-container {
    display: flex;

    > div {
      display: flex;
      flex-direction: column;
      &:first-child {
        flex: 2;
      }

      &:last-child {
        justify-content: space-between;
        align-items: flex-end;
        flex: 1;
      }

      .action-open {
        border-radius: 50%;
        background-color: #db9123;
        padding: 5px;
        height: 35px;
        width: 35px;
        color: #172943;
      }

      .job-code,
      .job-date {
        font-size: 14px;
        font-weight: 800;
        line-height: 24px;
        color: #373636;
        font-family: "Lato", sans-serif;
      }

      .job-view {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 800;
        line-height: 16px;
      }
    }
  }
  .progress {
    margin-top: -3px;
    height: 10px;
  }
}

.img-container {
  position: relative;

  .action-btn {
    position: absolute;
    border-radius: 50%;
    background-color: #db9123;
    padding: 5px;
    height: 35px;
    width: 35px;
    color: #172943;
    &.action {
      right: 10px;
      top: 10px;
    }
    &.directions {
      left: 10px;
      top: 10px;
    }
  }

  .job-submitted {
    position: absolute;
    right: 0;
    bottom: 3px;
    background-color: #172943;
    padding: 5px 10px;
    color: #ffffff;
    text-transform: uppercase;
    border-top-left-radius: 10px;
    font-weight: 800;
  }
}

.page-title {
  display: flex;
  align-items: center;
  margin: 0 16px;
  > * {
    flex: 1;
  }
  .filter-orderBy {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .orange-circle:first-child {
      margin-right: 10px;
    }
  }
}

.no-jobs {
  text-align: center;
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

h2.bold {
  font-weight: 700;
}
</style>
