<template>
  <div class="orange-circle filter" id="click-trigger" @click="popoverOpen = !popoverOpen; event = $event">
    <uil-sort-amount-down size="25px" />
  </div>
  <ion-popover :is-open="popoverOpen" :event="event" @didDismiss="popoverOpen = false" class="orderby-popover">
    <ion-content class="ion-padding" scroll-y="false">
      <div class="popover-container">
        <div class="popover-title">
          Sort By
        </div>
        <ion-list>
          <ion-radio-group v-model="internalModelValue" allow-empty-selection>
            <ion-item>
              <ion-label>Start Date <uil-angle-double-up class="order-icon" size="25px" /></ion-label>
              <ion-radio slot="start" value="start-date-asc" />
            </ion-item>
            <ion-item>
              <ion-label>Start Date <uil-angle-double-down class="order-icon" size="25px" /></ion-label>
              <ion-radio slot="start" value="start-date-desc" />
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </ion-content>
  </ion-popover>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRadio,
  IonRadioGroup,
} from '@ionic/vue';
import {
  UilAngleDoubleDown,
  UilAngleDoubleUp,
  UilSortAmountDown,
} from '@iconscout/vue-unicons';
import vModelMixin from '@/mixins/v-model';

export default {
  mixins: [
    vModelMixin,
  ],
  components: {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
    IonRadio,
    IonRadioGroup,
    UilAngleDoubleDown,
    UilAngleDoubleUp,
    UilSortAmountDown,
  },
  props: {
    modelValue: String,
  },
  data () {
    return {
      event: null,
      popoverOpen: false,
    };
  },
};
</script>

<style scoped lang="scss">
.orange-circle {
  border-radius: 50%;
  background-color: #db9123;
  padding: 5px;
  height: 35px;
  width: 35px;
  color: #172943;
}

.popover-title {
  color: #373636;
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 18px;
}

.order-icon {
  margin-bottom: -5px;
  color: #DB9123;
}
</style>
